import React from 'react'
import './Header.css';
import Logo from "./iamge/logo1.png"
import {Link} from 'react-router-dom'

function Header() {
    return (
        <>  
            <div className='customer-care'>
                <h4><i class="fa-solid fa-headset"></i> 9540-120-120</h4>
            </div>
            <header className="header">
                <div className="logo">
                <a href="https://resaleadvisor.com/" target="_blank" rel="noopener noreferrer">
                    <img src={Logo} alt="logo/image" />
                </a>

                </div>
            </header>
        </>
    )
}

export default Header
