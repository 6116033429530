import React from 'react'
import Header from './Header'
import Home from './Home'
import Filter from './Filter'

function Inventory() {
    return (
        <>
            <Header/>
            <Filter/>
        </>
    )
}

export default Inventory
